import React, { Component } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import Button from '@material-ui/core/Button'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField'
import ReglasService from '../services/reglas.service'
import UserAge from '../services/userAge.service'

import Swal from 'sweetalert2'
import CancelIcon from '@material-ui/icons/Cancel';
// import './Algoritmo.css'


class Algoritmo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reglas: [],
      openModal: false,
      id_icc: 0,
      id_bc: 0,
      icc: [],
      score: [],
      id_type: 0,
      type: [
        {
          value: 'Success'
        },
        {
          value: 'Finerio'
        }
      ],
      userAge: [],
      usersByUserAge: []
    }

    this.limitAge = React.createRef()
    this.attemps = React.createRef()
  }

  async componentDidMount() {
    await this.getRules()
    await this.getICC()
    await this.getBC()

    await this.getUsersByUserAge()
    await this.getUserAgeConfig()
  }

  getRules = async () => {
    let reglasService = new ReglasService()

    try {
      let reglas = await reglasService.getRules(localStorage.getItem('userlogged'))
      this.setState({ reglas })
    } catch (e) {
      return e
    }
  }

  getICC = async () => {
    let reglasService = new ReglasService()

    try {
      let icc = await reglasService.getICC()
      this.setState({ icc })
    } catch (e) {
      return e
    }
  }

  getBC = async () => {
    let reglasService = new ReglasService()

    try {
      let score = await reglasService.getBC()
      this.setState({ score })
    } catch (e) {
      return e
    }
  }

  deleteRule = async (id) => {
    let reglasService = new ReglasService()

    let result = await reglasService.deleteRule(id)
    if (result.statusCode !== "500") {
      Swal.fire({
        title: `Exito`,
        text: `La regla fue modificada`,
        icon: 'success',
        showConfirmButton: false
      })
    } else {
      Swal.fire({
        title: `Error`,
        text: `Ocurrio un error intente de nuevo`,
        icon: 'error',
        showConfirmButton: false
      })
    }
    await this.getRules()
  }

  insertRule = async () => {
    let { id_icc, id_bc, id_type } = this.state
    let reglasService = new ReglasService()

    try {
      let result = await reglasService.insertRule({
        icc_id: id_icc,
        score_id: id_bc,
        type: id_type,
        email: localStorage.getItem('userlogged')
      })

      console.log(result)

      if (result.statusCode === "500") {
        Swal.fire({
          title: `Error`,
          text: `Ocurrio un error intente de nuevo`,
          icon: 'error',
          showConfirmButton: false
        })
      } else {
        Swal.fire({
          title: `Exito`,
          text: `Se ha insertado la nueva regla`,
          icon: 'success',
          showConfirmButton: false
        })
      }

    } catch (e) {
      Swal.fire({
        title: `Error`,
        text: `Ocurrio un error intente de nuevo`,
        icon: 'error',
        showConfirmButton: false
      })
    }

    await this.getRules()
  }

  handleChange = (event, type) => {
    if (type) {
      this.setState({ id_icc: event.target.value })
    } else {
      this.setState({ id_bc: event.target.value })
    }
  }

  handleChangeType = (event) => {
    this.setState({ id_type: event.target.value })

    console.log(this.state)
  }

  downloadFile = () => {
    window.open('http://localhost:4412/public/September.csv')
    // let service = new UserService()

    // let result = service.getFile()

    // console.log(result)
  }

  async updateUserAgeConfig() {
    let userAgeService = new UserAge()

    try {
      let result = await userAgeService.updateUserAgeConfig({
        attemps: Number(this.attemps.current.value),
        user_age: Number(this.limitAge.current.value)
      })

      Swal.fire({
        title: result.statusCode === "500" ? `Error` : `Exito`,
        text: result.statusCode === "500" ? `Ocurrio un error intente de nuevo` : `Se ha insertado la nueva regla`,
        icon: result.statusCode === "500" ? 'error' : 'success',
        showConfirmButton: false,
        timer: 3000
      })

      await this.getUserAgeConfig()
    } catch (e) {
      Swal.fire({
        title: `Error`,
        text: `Ocurrio un error intente de nuevo`,
        icon: 'error',
        showConfirmButton: false,
        timer: 3000
      })
    }
  }

  async getUsersByUserAge() {
    let userAgeService = new UserAge()

    try {
      let usersByUserAge = await userAgeService.getUsersByUserAge()
      this.setState({ usersByUserAge })
    } catch (e) {
      return e
    }
  }

  async getUserAgeConfig() {
    let userAgeService = new UserAge()

    try {
      let userAge = await userAgeService.getUserAgeConfig()
      this.setState({ userAge })
    } catch (e) {
      return e
    }
  }

  render() {
    return (
      <Grid item xs={9} style={{ margin: '0 0 20px', fontFamily: 'Slightpay-light' }}>
        <h3 style={{ marginTop: '15px' }}>Lista de Reglas</h3>
        <div style={{ display: 'flex', marginBottom: '15px' }}>
          <FormControl variant="outlined">
            <InputLabel id="demo-simple-select-outlined-label">ICC</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={this.state.id_icc}
              onChange={(event) => { this.handleChange(event, 1) }}
              label="ICC"
            >
              {this.state.icc.map((row) => (
                <MenuItem value={row.id}>{row.valor}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" style={{ margin: '0 30px' }}>
            <InputLabel id="demo-simple-select-outlined-label">BC</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={this.state.id_bc}
              onChange={(event) => { this.handleChange(event) }}
              label="BC"
            >
              {this.state.score.map((row) => (
                <MenuItem value={row.id}>{row.valor}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" style={{ margin: '0 30px 0 0' }}>
            <InputLabel id="demo-simple-select-outlined-label">Tipo</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={this.state.id_type}
              onChange={(event) => { this.handleChangeType(event) }}
              label="BC"
            >
              {this.state.type.map((row) => (
                <MenuItem value={row.value}>{row.value}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button variant="contained" style={{ backgroundColor: "rgb(26, 76, 205)", color: "white", fontSize: '12px', fontFamily: 'Slightpay-bold' }} onClick={() => { this.insertRule() }}>Agregar</Button>
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead style={{ backgroundColor: 'rgb(26, 76, 205)', fontSize: '8px' }}>
              <TableRow>
                <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">ID</TableCell>
                <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">ICC</TableCell>
                <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">BuroBC</TableCell>
                <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">Tipo</TableCell>
                <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.reglas.map((row) => (
                <TableRow key={row.id}>
                  <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{row.id}</TableCell>
                  <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">{row.status_icc.valor}</TableCell>
                  <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{row.status_score.valor}</TableCell>
                  <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">{row.type}</TableCell>
                  <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">
                    <div style={{ display: 'flex', justifyContent: 'space-around', cursor: 'pointer' }} onClick={() => { this.deleteRule(row.id) }}>
                      <CancelIcon />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <h3 style={{ marginTop: '15px' }}>Configuracion de maximo de Edad</h3>
        <div style={{ display: 'flex', marginBottom: '15px' }}>
          <TextField
            id="outlined-read-only-input"
            label="Limite de edad"
            variant="outlined"
            style={{ width: '20%', marginRight: '15px' }}
            InputLabelProps={{ shrink: true }}
            inputRef={this.limitAge}
            type='number'
          />
          <TextField
            id="outlined-read-only-input"
            label="Intentos maximos"
            variant="outlined"
            style={{ width: '20%', marginRight: '15px' }}
            InputLabelProps={{ shrink: true }}
            inputRef={this.attemps}
            type='number'
          />
          <Button variant="contained" style={{ backgroundColor: "rgb(26, 76, 205)", color: "white", fontSize: '12px', fontFamily: 'Slightpay-bold' }} onClick={async () => { await this.updateUserAgeConfig() }}>Modificar</Button>
        </div>
        {
          this.state.userAge.length !== 0 ?
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead style={{ backgroundColor: 'rgb(26, 76, 205)', fontSize: '8px' }}>
                  <TableRow>
                    <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">Edad (Menor a)</TableCell>
                    <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">Intentos (Menor a)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.userAge.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{row.age}</TableCell>
                      <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">{row.attemps}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> :
            <p style={{ textAlign: 'center' }}>Aun no se configura la informacion de condiciones o el servicio esta fallando.</p>
        }

        <h3 style={{ marginTop: '15px' }}>Personas que entraron a la condicion</h3>
        {
          this.state.usersByUserAge.length !== 0 ?
            <TableContainer component={Paper} style={{ marginBottom: '15px' }}>
              <Table aria-label="customized table">
                <TableHead style={{ backgroundColor: 'rgb(26, 76, 205)', fontSize: '8px' }}>
                  <TableRow>
                  <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">Nombre</TableCell>
                    <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">Edad</TableCell>
                    <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">Intentos</TableCell>
                    <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.userAge.length !== 0 ?
                    this.state.usersByUserAge.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{`${row.usuario.nombre} ${row.usuario.apellido_paterno} ${row.usuario.apellido_materno ? row.usuario.apellido_materno : ''}`}</TableCell>
                        <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">{row.usuario.edad}</TableCell>
                        <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">{row.attemp}</TableCell>
                        <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">{row.attemp >= this.state.userAge[0].attemps - 1 ? 'Flujo normal' : 'Primero Finerio'}</TableCell>
                      </TableRow>
                    )) : null}
                </TableBody>
              </Table>
            </TableContainer> :
            <p style={{ textAlign: 'center' }}>No hay conexion con el servicio.</p>
        }
        <br></br>
      </Grid>
    )
  }
}

export { Algoritmo as default }
