import React from 'react'
import PropTypes from 'prop-types'
import Clear from '@material-ui/icons/Clear'
import { DialogContent, Dialog, Toolbar } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import './modal.css'

/**
 * @author  
 * @description Pure component to render a Modal with custom body (props.children)
 * @class Modal
 * @extends {React.Component}
 */
class Modal extends React.Component {
  render () {
    const {
      styles,
      handleModal,
      children
    } = this.props

    let modalHeaderStyle
    let modalTitleStyle

    if (styles) {
      modalHeaderStyle = styles.modalHeaderStyle
      modalTitleStyle = styles.modalTitleStyle
    } else {
      modalHeaderStyle = undefined
      modalTitleStyle = undefined
    }

    const TitleIcon = this.props.titleIcon
    return (
      <Dialog classes={{ paper: 'modalBorder' }} open={this.props.openModal} maxWidth={false} onBackdropClick={() => {
        this.props.handleModal(false)
      }}>
        <AppBar
          position='static'
          color='primary'
          classes={{ colorPrimary: modalHeaderStyle || 'modalHeader' }}
        >
          <Toolbar>
            {TitleIcon ? <TitleIcon className={'modalTitleIcon'} /> : null}
            <p className={modalTitleStyle || 'modalTitle'}>{this.props.title}</p>
          </Toolbar>
          <Clear className={'modalExit'} onClick={() => { handleModal(false) }} />
        </AppBar>
        <DialogContent classes={{ root: 'modalBodyContainer' }}>
          <div className={'modalBody'}>
            {children}
          </div>
        </DialogContent>
      </Dialog>
    )
  }
}

Modal.propTypes = {
  openModal: PropTypes.bool,
  handleModal: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node,
  titleIcon: PropTypes.func,
  styles: PropTypes.func
}

export { Modal as default }
