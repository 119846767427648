import React, { Component } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import BankService from '../services/bank.service'

import './font.css'


class BankReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            reports: [],
            openModal: false
        }

        this.limite = React.createRef()
        this.ingresos = React.createRef()
    }

    async componentDidMount() {
        await this.getInformation()
    }

    async getInformation() {
        let email = localStorage.getItem('userlogged')
        let bankService = new BankService()

        let responseBankService = await bankService.getReports(email)

        this.setState({ reports: responseBankService })
    }

    render() {
        return (
            <Grid item xs={9} style={{ margin: '0 0 20px', fontFamily: 'Slightpay-light' }}>
                <h3 style={{ marginTop: '15px' }}>Reporte del banco</h3>
                <TableContainer component={Paper}>
                    <Table aria-label="customized table">
                        <TableHead style={{ backgroundColor: 'rgb(26, 76, 205)', fontSize: '8px' }}>
                            <TableRow>
                                <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">Nombre</TableCell>
                                <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">RFC</TableCell>
                                <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">Cantidad solicitada</TableCell>
                                <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">Fecha</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.reports.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{row.usuario.nombre} {row.usuario.apellido_paterno}</TableCell>
                                    <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{row.usuario.rfc}</TableCell>
                                    <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{row.lead}</TableCell>
                                    <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">{row.date}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        )
    }
}

export { BankReport as default }
