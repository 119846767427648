import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Grid from '@material-ui/core/Grid'


let ReportesBC = () => {
  let [month, setMonth] = useState(0)
  let months = ['January', 'Febrary', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

  let downloadFile = () => {
    window.open(`http://localhost:4412/public/${months[month]}.csv`)
  }

  let changeMonth = (ev) => {
    let id = ev.target.value
    setMonth(id)
  }

  return (
    <Grid item xs={9} style={{ margin: '0 0 20px', fontFamily: 'Slightpay-light' }}>
      <h3 style={{ marginTop: '15px' }}>Reportes</h3>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '96%' }}>
        <FormControl style={{ marginBottom: '20px' }}>
          <InputLabel id="demo-simple-select-label">Mes</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={month}
            onChange={ev => { changeMonth(ev) }}
          >
            {months.map((monthValue, key) => {
              return (
                <MenuItem value={key}>{monthValue}</MenuItem>
              )
            })}
          </Select>
        </FormControl>
        <Button variant="contained" style={{ backgroundColor: 'rgb(26, 76, 205)', color: 'white' }} onClick={downloadFile}>Bajar CSV del mes</Button>
      </div>
    </Grid>
  )
}

export default ReportesBC