import React, { useEffect, useRef, useState } from 'react'
//Services
import UserService from "../../services/user.service"
//Material UI Components
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import Button from '@material-ui/core/Button'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox';
import Modal from '../../components/modal/modal'
//Styles
import styles from "./homeHook.module.css";
import Loading from '../../components/Loading/Loading'
import Error from '../../components/Error/Error'

const HomeHook = () => {
    const [error, setError] = useState(false)
    const [mensajeError, setMensajeError] = useState("")
    const [loading, setLoading] = useState(true)
    // const [nombre, setNombre] = useState("")
    // const [apellidoPaterno, setApellidoPaterno] = useState("")
    // const [apellidoMaterno, setApellidoMaterno] = useState("")
    const rfcInputRef = useRef(null)
    const [users, setUsers] = useState([])
    const [usersFiltered, setUsersFiltered] = useState([])
    
    const [openModal, setOpenModal] = useState(false)
    const [checkedStatus, setCheckedStatus] = useState({
        aceptados: false,
        rechazados: false,
        observacion: false
    })
    //Referencias
    // const prevValues = useRef({ nombre, apellidoPaterno, apellidoMaterno })
    //Use Effect
    useEffect(() => {
        getUsers()
    }, [])

    const getUsers = async (body) => {
        try {
            let userService = new UserService()
            let usersResponse = await userService.getUsers(body)
            setUsers(usersResponse)
            setUsersFiltered(usersResponse)

            setLoading(false)
        } catch (error) {
            console.log(error);
            setError(true)
            setMensajeError("Error al conectar con el servidor")
        }
    }

    // const getUsersByName = async () => {
    //     try {
    //         setLoading(true)
    //         let userService = new UserService()
    //         if (prevValues.current.nombre !== nombre || prevValues.current.apellidoPaterno !== apellidoPaterno || prevValues.current.apellidoMaterno !== apellidoMaterno) {
    //             let userResponse = await userService.getUsersByName(nombre, apellidoPaterno, apellidoMaterno)
    //             setUsers(userResponse)
    //             setLoading(false)
    //         }
    //     } catch (error) {
    //         console.log(error);
    //         setError(true)
    //         setMensajeError("Error al conectar con el servidor")
    //     }
    // }

    const getUserByRFC = async () => {
        setUsersFiltered(users.filter((user) => {
            user.rfc.includes(rfcInputRef.current.value) && console.log(user)
            return user.rfc.includes(rfcInputRef.current.value)
        }
        ))
    }

    function getMessage(value){
        let mensaje = "";

        switch (value) {
            case -1:
                mensaje = "Consumidor Fallecido.";
                break;
            case -5:
                mensaje = "Expediente con todas las cuentas cerradas y por lo menos con una en atraso mayor o igual a 90 días.";
                break;
            case -6:
                mensaje = "Expediente con todas sus cuentas con antigüedad menor a 6 meses y al menos una tiene MOP >= 3";
                break;
            case -7:
                mensaje = "Expediente con todas sus cuentas con antigüedad menor a 6 meses y al menos una tiene MOP >= 2";
                break;
            case -8:
                mensaje = "Expediente no tiene al menos una cuenta actualizada en el último año o con antigüedad mínima de 6 meses, y/o con cuentas que no se incluyen en el cálculo del BC-Score";
                break;
            case -9:
                mensaje = "Expediente sin cuentas para cálculo de BC-Score.";
                break;
            case -500:
                mensaje = "Buro de crédito no regreso score del usuario, el usuario debe corregir su información";
                break;
            default:
                break;
        }

        return mensaje;
    }

   
    const handleModal = async (openModal, buscar) => {
        if (buscar) {
            let obj = {}
            if (checkedStatus.observacion) {
                obj.observacion = true
            }
            if (checkedStatus.aceptados) {
                obj.aceptados = true
            }
            if (checkedStatus.rechazados) {
                obj.rechazados = true
            }
            await getUsers(obj)
        }
        setOpenModal(openModal)
    }
    const handleChange = (event) => {
        setCheckedStatus({ ...checkedStatus, [event.target.name]: event.target.checked })
    }
    if (error === true) {
        return (
            <Error mensajeError={mensajeError} />
        )
    }
    return (
        <>
            <Grid container style={{ justifyContent: 'center', fontFamily: 'Slightpay-light' }}>
                <Modal openModal={openModal} title='Busqueda avanzada' handleModal={handleModal} style={{ fontFamily: 'Slightpay-light' }}>
                    <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexWrap: 'wrap', padding: '20px' }}>
                        <div style={{ flex: '1 1 100%', justifyContent: 'center', display: 'flex' }}>
                            <FormControl component="fieldset" >
                                <FormLabel component="legend" style={{ fontFamily: 'Slightpay-bold' }}>Seleccione los filtros</FormLabel>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox checked={checkedStatus.aceptados} onChange={handleChange} name="aceptados" />}
                                        label="Aceptados"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={checkedStatus.rechazados} onChange={handleChange} name="rechazados" />}
                                        label="Rechazados"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={checkedStatus.observacion} onChange={handleChange} name="observacion" />}
                                        label="En observacion"
                                    />
                                </FormGroup>
                            </FormControl>
                        </div>
                        <Button variant="contained" className={styles.btnBuscar}
                            onClick={() => { handleModal(false, true) }}>Buscar</Button>
                    </div>
                </Modal>
                <Grid item xs={9} style={{ padding: '0 0 25px 0' }}>
                    <h3>Lista de usuarios</h3>
                    <Grid container style={{ justifyContent: 'space-between', margin: '0 0 20px' }}>
                        <Grid item xs={3}>
                            <TextField
                                id="outlined-read-only-input"
                                label="Buscar por RFC"
                                variant="outlined"
                                style={{ width: '90%' }}
                                onChange={getUserByRFC}
                                inputRef={rfcInputRef}
                            />
                        </Grid>
                        {/* <Grid item xs={3}>
                            <TextField
                                id="outlined-read-only-input"
                                label="Ap Paterno"
                                variant="outlined"
                                style={{ width: '90%' }}
                                value={apellidoPaterno}
                                onChange={e => setApellidoPaterno(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                id="outlined-read-only-input"
                                label="Ap Materno"
                                variant="outlined"
                                style={{ width: '90%' }}
                                value={apellidoMaterno}
                                onChange={e => setApellidoMaterno(e.target.value)}
                            />
                        </Grid> */}
                        <Grid item xs={3} style={{ justifyContent: 'right', alignItems: 'center', display: 'flex' }}>
                            {/* <Button variant="contained" className={styles.btnBuscar} onClick={() => { getUserByRFC() }}>Buscar</Button> */}
                            <Button variant="contained" className={styles.btnBuscar} onClick={() => { handleModal(true) }}>Filtros</Button>
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                            <TableHead className={styles.tableHead}>
                                <TableRow>
                                    <TableCell className={styles.tableEncabezados}>Nombre</TableCell>
                                    <TableCell className={styles.tableEncabezados} align="left">Dirección</TableCell>
                                    <TableCell className={styles.tableEncabezados} align="left">Edad</TableCell>
                                    <TableCell className={styles.tableEncabezados} align="left">RFC</TableCell>
                                    <TableCell className={styles.tableEncabezados} align="left">CP</TableCell>
                                    <TableCell className={styles.tableEncabezados} align="left">ICC</TableCell>
                                    <TableCell className={styles.tableEncabezados} align="left">BC</TableCell>
                                    <TableCell className={styles.tableEncabezados} align="left">Score</TableCell>
                                    <TableCell className={styles.tableEncabezados} align="left">SNH</TableCell>
                                    <TableCell className={styles.tableEncabezados} align="left">Puntos totales</TableCell>
                                    <TableCell className={styles.tableEncabezados} align="left">Status</TableCell>
                                    {/* <TableCell className={styles.tableEncabezados} align="left">Obs.</TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {usersFiltered.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell className={styles.tableCellDatos} component="th" scope="row">
                                            {`${row.nombre.toUpperCase()} ${row.apellido_paterno.toUpperCase()} ${row.apellido_materno ? row.apellido_materno.toUpperCase() : ''}`}
                                        </TableCell>
                                        <TableCell className={styles.tableCellDatos} align="left">
                                            {`${row.direccion.toUpperCase()} ${row.ciudad.toUpperCase()}, ${row.estado.toUpperCase()}`}
                                            </TableCell>
                                        <TableCell className={styles.tableCellDatos} align="left">{row.edad}</TableCell>
                                        <TableCell className={styles.tableCellDatos} align="left">{row.rfc}</TableCell>
                                        
                                       
                                        <TableCell className={styles.tableCellDatos} align="left">{row.cp}</TableCell>
                                       
                                        <TableCell className={styles.tableCellDatos} align="left">{row.score.status_icc.valor}</TableCell>
                                        <TableCell className={styles.tableCellDatos} align="left">{row.score.status_score.valor}</TableCell>
                                        <TableCell className={styles.tableCellDatos} align="left">{
                                        <h1 title={getMessage(row.score.valor_score)} className={styles.tableCellDatos}>{row.score.valor_score}</h1>
                                        }</TableCell>

                                        <TableCell className={styles.tableCellDatos} align="left">{row.score?.scoring_no_hit_report?.data?.valueScoreNoHit ? row.score?.scoring_no_hit_report?.data?.valueScoreNoHit : '--'}</TableCell>
                                        <TableCell className={styles.tableCellDatos} align="left">{row.score?.data_user_score ? row.score?.data_user_score.totalPoints : '--'}</TableCell>

                                        <TableCell className={styles.tableCellDatos} align="left">{
                                        <h1 style={{ color: row.status.valor === 'Rechazado' ? '#da7a7a' : '#44b944' }} title={row.status.valor === 'Rechazado' && row.score.scoring_response?.data?.motivo} className={styles.tableCellDatos}>{row.status.valor}</h1>
                                        }</TableCell>
                                         
                                        {/* {row.observacion ?
                                            <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold', color: '#44b944' }} align="center">{'Si'}</TableCell> :
                                            <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold', color: '#da7a7a' }} align="center">{'No'}</TableCell>
                                        } */}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {loading === true && (
                        <Loading />
                    )
                    }

                </Grid>
            </Grid>
        </>
    )
}
export default HomeHook
