/*eslint-disable*/
import React, { Component } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import Button from '@material-ui/core/Button'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import SlightpayService from '../services/slightpay.service'
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox';
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import CircularProgress from '@material-ui/core/CircularProgress'
import Modal from '../components/modal/modal'
import './slightpay.css'
import Error from '../components/Error/Error'
import Loading from '../components/Loading/Loading'


class SlightpayContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      users: [],
      openModal: false,
      noPayments: [1, 2, 3, 4],
      info: [],
      loading: true,
      loadingModal:true,
      error: false,
      msgError: ""
    }

    this.name = React.createRef()
    this.lastName = React.createRef()
  }

  async componentDidMount() {
    await this.getUsersContainer()
  }

  getUsersContainer = async () => {
    let slightpayService = new SlightpayService()
    let users = await slightpayService.getUsers()
    console.log(users);
    if (users.status !== 'error') {
      this.setState({
        users,
        loading: false
      })
    } else {
      this.setState({
        error: true,
        msgError: "Error en el servidor"
      })
    }

  }

  handleModal = (openModal) => {
    this.setState({ openModal })

    if (!openModal) {
      setTimeout(() => {
        this.setState({ info: [], loadingModal: true })
      }, 2000);
    }
  }
  
  getPurchases = async (id) => {
    this.handleModal(true) 
    setTimeout(() => {
      this.setState({ info: this.state.users[id].purchases, loadingModal: false })
      
    }, 1500);
  }

  // Material UI Function
  handleChange = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.checked })
  }


  render() {
    // Necesito en la base de datos agregar las condiciones para que un score pase y asi poder obtenerlos
    // Por lo pronto los tengo puestos aqui
    return (
      <>
        {
          this.state.error && <Error />
        }

        <div>
          {
            this.state.loading ?
              <Grid container style={{ justifyContent: 'center', alignItems: 'center', height: '100%', fontFamily: 'Slightpay-light' }}>
                <CircularProgress />
              </Grid>
              :
              this.state.users.length !== 0 ?
                <Grid container style={{ justifyContent: 'center', fontFamily: 'Slightpay-light' }}>
                  <Modal openModal={this.state.openModal} title='Informacion de pagos' handleModal={this.handleModal}>
                    <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexWrap: 'wrap', padding: '20px' }}>


                      <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                          <TableHead style={{ backgroundColor: 'rgb(26, 76, 205)', fontSize: '8px' }}>
                            <TableRow>
                              <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }}>ID</TableCell>
                              <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">Productos</TableCell>
                              <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">Status</TableCell>
                              <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">Total</TableCell>
                              <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">C 1</TableCell>
                              <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">C 2</TableCell>
                              <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">C 3</TableCell>
                              <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">C 4</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              this.state.info.length !== 0 &&   
                                this.state.info.map((purchase) => (

                                  <TableRow key={purchase.id}>
                                    <TableCell style={{ fontSize: '13px' }} component="th" scope="row">
                                      {`${purchase.id}`}
                                    </TableCell>
                                    <TableCell >
                                      {
                                        purchase.products.map((producto) => (

                                          <p>
                                            {`${producto.name}`}
                                          </p>

                                        ))
                                      }
                                    </TableCell>
                                    <TableCell style={{ fontSize: '13px' }} align="left">{purchase.status}</TableCell>
                                    <TableCell style={{ fontSize: '13px' }} align="left">{purchase.totalAmount}</TableCell>
                                    {
                                      purchase.payments.map((payment) => {
                                        return (
                                          payment.status === "SUCCESSFUL" ?
                                            <TableCell style={{ fontSize: '13px' }} align="left">
                                              <CheckIcon />
                                            </TableCell> : payment.status === "PAYMENT_REJECTED" ?
                                              <TableCell style={{ fontSize: '13px' }} align="left">
                                                <ClearIcon />
                                              </TableCell> :
                                              <TableCell style={{ fontSize: '13px' }} align="left">
                                                <HourglassEmptyIcon />
                                              </TableCell>
                                        )
                                      })
                                    }
                                  </TableRow>

                                ))
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                      {this.state.loadingModal  && <div><CircularProgress /> </div> }

                    </div>
                  </Modal>
                  <Grid item xs={11}   style={{ padding: '0 0 25px 0' }}>
                    <h3>Lista de usuarios de Slightpay</h3>
                    <TableContainer component={Paper}>
                      <Table aria-label="customized table">
                        <TableHead style={{ backgroundColor: 'rgb(26, 76, 205)', fontSize: '8px' }}>
                          <TableRow>
                            <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }}>Nombre</TableCell>
                            <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">Correo</TableCell>
                            <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">RFC</TableCell>
                            <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">Puntos</TableCell>
                            <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">Estatus</TableCell>
                            <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">C 1</TableCell>
                            <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">C 2</TableCell>
                            <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">C 3</TableCell>
                            <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">C 4</TableCell>
                            <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">Detalles</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.users.map((row, key) => (
                            <TableRow key={row.id}>
                              <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} component="th" scope="row">
                                {`${row.firstName.toUpperCase()} ${row.lastName.toUpperCase()}`}
                              </TableCell>
                              <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{`${row.email}`}</TableCell>
                              <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{row.rfc}</TableCell>
                              <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{row.points}</TableCell>
                              {row.purchases.length !== 0 && row.purchases[0].status === "SUCCESSFUL" ?
                                <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">
                                  <CheckIcon />
                                </TableCell> : row.purchases.length == 0 ?
                                  // Falta validar con mas status aparte, segun entiend son:
                                  <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">
                                    <RemoveCircleOutlineIcon />
                                  </TableCell> :
                                  <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">
                                    <HourglassEmptyIcon />
                                  </TableCell>
                              }
                              {
                                row.purchases.length === 0 ?
                                  this.state.noPayments.map(() => {
                                    return (
                                      <TableCell style={{ fontSize: '13px' }} align="left"></TableCell>
                                    )
                                  })
                                  :
                                  row.purchases[0].payments.map((payment) => (

                                    payment.status === "SUCCESSFUL" ?
                                      <TableCell style={{ fontSize: '13px' }} align="left">
                                        <CheckIcon />
                                      </TableCell> : payment.status === "PAYMENT_REJECTED" ?
                                        <TableCell style={{ fontSize: '13px' }} align="left">
                                          <ClearIcon />
                                        </TableCell> :
                                        <TableCell style={{ fontSize: '13px' }} align="left">
                                          <HourglassEmptyIcon />
                                        </TableCell>

                                  ))
                              }
                              {
                                row.purchases.length !== 0 ?
                                  <TableCell style={{ fontSize: '13px' }} align="left">
                                    <div style={{ cursor: 'pointer', color: 'rgb(26, 76, 205)', fontFamily: 'Slightpay-bold' }} onClick={() => { this.getPurchases(key) }}>Ver</div>
                                  </TableCell> :
                                  <TableCell style={{ fontSize: '13px' }} align="left">
                                    <p></p>
                                  </TableCell>
                              }
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid> :
                <Grid container style={{ justifyContent: 'center', alignItems: 'center', height: '100%', fontFamily: 'Slightpay-light' }}>
                  <p style={{ textAlign: 'center' }}>No se ha encontrado informacion</p>
                </Grid>
          }
        </div>
      </>
    )
  }
}

export { SlightpayContainer as default }
