import React, { Component } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import BankService from '../services/bank.service'
import ReglasService from '../services/reglas.service'
import CpService from '../services/cp.service'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withRouter } from 'react-router-dom'

import './font.css'

// Hay que importar Reglas, CP y el bankService para el reporte de transacciones, pero todas son por email

class bankInformation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reglas: [],
      cpAuth: [],
      cpRest: [],
      reports: [],
      getInformation: {},
      loading: true
    }
  }

  async componentDidMount() {
    let bankService = new BankService()
    let reglasService = new ReglasService()
    let cpService = new CpService()

    let url = this.props.location.search
    let bankEmail = url.slice(url.indexOf('Id=') + 3, url.length)

    let reglas = await reglasService.getRules(bankEmail)
    let cpAuth = await cpService.getCpAuth(bankEmail)
    let cpRest = await cpService.getCps(bankEmail)
    let reports = await bankService.getReports(bankEmail)
    let getInformation = await bankService.getInformation(bankEmail)

    this.setState({
      reglas,
      cpAuth,
      cpRest,
      reports,
      getInformation,
      loading: false
    })
  }

  render() {
    console.log(this.state)
    return (
      <div>
        {
          !this.state.loading ?
            <Grid container style={{ justifyContent: 'center', fontFamily: 'Slightpay-light' }}>
              <Grid item xs={9} style={{ margin: '0 0 20px' }}>
                <h3>Informacion del banco</h3>
                <TableContainer component={Paper}>
                  <Table aria-label="customized table">
                    <TableHead style={{ backgroundColor: 'rgb(26, 76, 205)', fontSize: '8px' }}>
                      <TableRow>
                        <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">ID</TableCell>
                        <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">Nombre</TableCell>
                        <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">Capital</TableCell>
                        <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">Email</TableCell>
                        <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">Interes</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        <TableRow key={this.state.getInformation.id}>
                          <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{this.state.getInformation.id}</TableCell>
                          <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{this.state.getInformation.name}</TableCell>
                          <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{this.state.getInformation.capital}</TableCell>
                          <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{this.state.getInformation.email}</TableCell>
                          <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{this.state.getInformation.interest_rate}%</TableCell>
                        </TableRow>
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={9} style={{ margin: '0 0 20px' }}>
                <h3>Reglas</h3>
                <TableContainer component={Paper}>
                  <Table aria-label="customized table">
                    <TableHead style={{ backgroundColor: 'rgb(26, 76, 205)', fontSize: '8px' }}>
                      <TableRow>
                        <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">ID</TableCell>
                        <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">ICC</TableCell>
                        <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">BuroBC</TableCell>
                        <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">Tipo</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.reglas.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{row.id}</TableCell>
                          <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">{row.status_icc.valor}</TableCell>
                          <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{row.status_score.valor}</TableCell>
                          <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">{row.type}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={9} style={{ margin: '0 0 20px' }}>
                <h3>Cp Restringidos</h3>
                <TableContainer component={Paper}>
                  <Table aria-label="customized table">
                    <TableHead style={{ backgroundColor: 'rgb(26, 76, 205)', fontSize: '8px' }}>
                      <TableRow>
                        <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }}>ID</TableCell>
                        <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">Numero</TableCell>
                        <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">Colonia</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.cpRest.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{row.id}</TableCell>
                          <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">{row.numero}</TableCell>
                          <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">{row.colonia}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={9} style={{ margin: '0 0 20px' }}>
                <h3>Cp Autorizados</h3>
                <TableContainer component={Paper}>
                  <Table aria-label="customized table">
                    <TableHead style={{ backgroundColor: 'rgb(26, 76, 205)', fontSize: '8px' }}>
                      <TableRow>
                        <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }}>Conteo</TableCell>
                        <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">Limite</TableCell>
                        <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">CP Permitido</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.cpAuth.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{row.conteo}</TableCell>
                          <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{row.limite}</TableCell>
                          <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{row.cp_restringido.numero}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={9} style={{ margin: '0 0 20px' }}>
                <h3>Reporte de transacciones</h3>
                <TableContainer component={Paper}>
                  <Table aria-label="customized table">
                    <TableHead style={{ backgroundColor: 'rgb(26, 76, 205)', fontSize: '8px' }}>
                      <TableRow>
                        <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">Nombre</TableCell>
                        <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">RFC</TableCell>
                        <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">Cantidad solicitada</TableCell>
                        <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">Fecha</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.reports.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{row.usuario.nombre} {row.usuario.apellido_paterno}</TableCell>
                          <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{row.usuario.rfc}</TableCell>
                          <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{row.lead}</TableCell>
                          <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">{row.date}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid> :
            <Grid container style={{ justifyContent: 'center', alignItems: 'center', height: '100%', fontFamily: 'Slightpay-light' }}>
              <CircularProgress />
            </Grid>
        }
      </div>
    )
  }
}

export default withRouter(bankInformation)