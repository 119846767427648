import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { CP, Ingresos, Slightpay, Algoritmo, AdminBank, Reports, BankCreator, BankInformation, ReportBC, UserAdmin } from './'
import HomeHook from './Home/homeHook'
// import SlightpayPagosContainer from './Slightpay-Pagos/Slightpay-Pagos-Container' Hook para la pantalla de usuarios y compras


class MainContainer extends Component {
  render() {
    return (
      <div id='2' style={{ flex: '1 1 50%', padding: '10px', textAlign: 'left', overflow: 'auto', height: '98vh', justifyContent: 'center', display: 'flex', backgroundColor: '#f2f2f2' }}>

        {
          localStorage.getItem('type') === 'user' ?
            <Switch>
              <Route path='/home'>
                <HomeHook />
              </Route>
              <Route path='/slightpay'>
                <Slightpay />
              </Route>
              <Route path='/cp'>
                <CP />
              </Route>
              <Route path='/ingresos'>
                <Ingresos />
              </Route>
              <Route path='/algoritmo'>
                <Algoritmo />
              </Route>
              <Route path='/agregar-banco'>
                <BankCreator />
              </Route>
              <Route path='/bank-information'>
                <BankInformation />
              </Route>
              <Route path='/reporte-bc'>
                <ReportBC />
              </Route>
              <Route path='/user-admin'>
                <UserAdmin />
              </Route>
              <Redirect to='/home' />
            </Switch>
            : 
            <Switch>
              <Route path='/administracion'>
                <AdminBank />
              </Route>
              <Route path='/reporte'>
                <Reports />
              </Route>
              <Route path='/algoritmo'>
                <Algoritmo />
              </Route>
              <Route path='/ingresos'>
                <Ingresos />
              </Route>
              <Route path='/cp'>
                <CP />
              </Route>
              <Redirect to='/administracion' />
            </Switch>
        }
      </div>
    )
  }
}

export { MainContainer as default }
