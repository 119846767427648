import React, { Component } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import Button from '@material-ui/core/Button'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import BankService from '../services/bank.service'
import CancelIcon from '@material-ui/icons/Cancel'
import EditIcon from '@material-ui/icons/Edit'
import PublishIcon from '@material-ui/icons/Publish'
import * as firebase from 'firebase'
import Modal from '../components/modal/modal'
import Swal from 'sweetalert2'

import { withRouter } from 'react-router-dom'

import './font.css'

class bankContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      banks: [],
      openModal: false,
      modify: false
    }

    this.name = React.createRef()
    this.capital = React.createRef()
    this.email = React.createRef()
    this.interestRate = React.createRef()
  }

  async componentDidMount() {
    await this.getAllBanks()
  }

  /**
   * @author Brayan Salas
   * @description Method to get all banks registered on Slightpay Scoring
   * @memberof bankContainer
   */
  async getAllBanks() {
    let bankService = new BankService()

    let result = await bankService.getAllBanks()
    this.setState({
      banks: result
    })
  }

  /**
   * @author Brayan Salas
   * @description Method to go to bank-information which displays table info.
   * @memberof bankContainer
   */
  goTo = (params) => {
    this.props.history.push(`/bank-information?${params}`)
  }

  /**
   * @author Brayan Salas
   * @description Methdd to add/modify a bank
   * @memberof bankContainer
   */
  async addOrModifyBank() {
    let bankService = new BankService()
    let result

    try {
      result = await bankService.addBank(this.name.current.value, Number(this.capital.current.value), Number(this.interestRate.current.value), this.email.current.value)
    } catch (e) {
      Swal.fire({
        title: `Error`,
        text: `Ocurrio un error al agregar/modificar ${this.name.current.value}`,
        icon: 'error',
        showConfirmButton: false
      })
    }


    if (result) {
      if (!this.state.modify) {
        let password = Math.random().toString(36).slice(2)
        await firebase.auth().createUserWithEmailAndPassword(this.email.current.value, password).catch(function (error) {
          this.handleModal(false)
          Swal.fire({
            title: `Error`,
            text: `Ocurrio un error al registrar el banco`,
            icon: 'error',
            showConfirmButton: false
          })
        })
        this.handleModal(false)
        Swal.fire({
          title: `Exito`,
          text: `Se ha agregado el nuevo banco su password es: ${password}`,
          icon: 'success',
          showConfirmButton: true
        })
      } else {
        this.handleModal(false)
        Swal.fire({
          title: `Exito`,
          text: `Se ha modificado la informacion del banco ${this.name.current.value}`,
          icon: 'success',
          showConfirmButton: false
        })
      }

      await this.getAllBanks()
    }
  }

  /**
   * @author Brayan Salas
   * @description Method to handle modal information
   * @param {*} obj
   * @memberof bankContainer
   */
  async handleModifyBank(obj) {
    await this.handleModal(true)

    this.name.current.value = obj.name
    this.capital.current.value = obj.capital
    this.email.current.value = obj.email
    this.interestRate.current.value = obj.interest_rate
  }

  
  /**
   * @author Brayan Salas
   * @description Method to enable/disable a bank
   * @param {*} bankName
   * @param {*} id
   * @param {*} type
   * @memberof bankContainer
   */
  async handleEnDisBank(bankName, id, type) {
    let bankService = new BankService()

    if (type === 'disabled') {
      Swal.fire({
        title: `¿Deseas deshabilitar ${bankName}`,
        text: 'Puedes activarlo nuevamente en este panel',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        let bankResult = bankService.enableDisableBank(id, false)
        if (bankResult) {
          if (result.value) {
            Swal.fire({
              title: `Exito`,
              text: `Se ha deshabilitado el banco ${bankName}`,
              icon: 'success',
              showConfirmButton: false
            })
            await this.getAllBanks()
          }
        } else {
          Swal.fire({
            title: `Error`,
            text: `No se ha podido deshabilitar el banco ${bankName}`,
            icon: 'error',
            showConfirmButton: false
          })
        }
      })
    } else {
      Swal.fire({
        title: `¿Deseas habilitar ${bankName}`,
        text: 'Puedes desactivarlo nuevamente en este panel',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        let bankResult = bankService.enableDisableBank(id, true)
        if (bankResult) {
          if (result.value) {
            Swal.fire({
              title: `Exito`,
              text: `Se ha habilitado el banco ${bankName}`,
              icon: 'success',
              showConfirmButton: false
            })

            await this.getAllBanks()
          }
        } else {
          Swal.fire({
            title: `Error`,
            text: `No se ha podido deshabilitar el banco ${bankName}`,
            icon: 'error',
            showConfirmButton: false
          })
        }
      })
    }
  }

  /**
   * @author Brayan Salas
   * @description Method to handle Modal open/close
   * @memberof bankContainer
   */
  handleModal = async (openModal) => {
    this.setState({ openModal })
  }

  render() {
    return (
      <Grid item xs={9} style={{ margin: '0 0 20px', fontFamily: 'Slightpay-light' }}>
        <Modal openModal={this.state.openModal} title={this.state.modify ? 'Modificar banco' : 'Agregar banco'} handleModal={this.handleModal}>
          <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexWrap: 'wrap', padding: '20px', width: '350px' }}>
            <TextField
              id="outlined-read-only-input"
              label="Nombre"
              variant="outlined"
              style={{ width: '90%', marginBottom: '15px' }}
              inputRef={this.name}
            />
            <TextField
              id="outlined-read-only-input2"
              label="Email"
              variant="outlined"
              style={{ width: '90%', marginBottom: '15px' }}
              inputRef={this.email}
            />
            <TextField
              id="outlined-read-only-input3"
              label="Capital"
              variant="outlined"
              style={{ width: '90%', marginBottom: '15px' }}
              inputRef={this.capital}
            />
            <TextField
              id="outlined-read-only-input4"
              label="Interes"
              variant="outlined"
              style={{ width: '90%', marginBottom: '15px' }}
              inputRef={this.interestRate}
            />
            <Button variant="contained" style={{ backgroundColor: "rgb(26, 76, 205)", color: "white", fontSize: '12px', height: '50px', marginBottom: '10px', fontFamily: 'Slightpay-bold' }} onClick={() => { this.addOrModifyBank() }}>Agregar</Button>
          </div>
        </Modal>
        <h3>Lista de bancos</h3>
        <div style={{ textAlign: 'right' }}>
          <Button variant="contained" style={{ backgroundColor: "rgb(26, 76, 205)", color: "white", fontSize: '12px', height: '50px', marginBottom: '10px', fontFamily: 'Slightpay-bold' }} onClick={() => {
            this.handleModal(true)
            this.setState({ modify: false })
          }}>Agregar</Button>
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead style={{ backgroundColor: 'rgb(26, 76, 205)', fontSize: '8px' }}>
              <TableRow>
                <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">ID</TableCell>
                <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">Nombre</TableCell>
                <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">Capital</TableCell>
                <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">Email</TableCell>
                <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">Interes</TableCell>
                <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">Estatus</TableCell>
                <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.banks.map((row) => (
                <TableRow key={row.id}>
                  <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{row.id}</TableCell>
                  <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold', cursor: 'pointer' }} align="left" onClick={() => { this.goTo(`bankId=${row.email}`) }}>{row.name}</TableCell>
                  <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{row.capital}</TableCell>
                  <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{row.email}</TableCell>
                  <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{row.interest_rate}%</TableCell>
                  <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{row.active ? 'Activo' : 'Desactivado'}</TableCell>
                  <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">
                    <EditIcon style={{ marginRight: '15px', cursor: 'pointer' }} onClick={() => {
                      this.handleModifyBank(row)
                      this.setState({ modify: true })
                    }} />
                    {
                      row.active ?
                        <CancelIcon style={{ cursor: 'pointer' }} onClick={() => {
                          this.handleEnDisBank(row.name, row.id, 'disabled')
                        }} /> :
                        <PublishIcon
                          style={{ cursor: 'pointer' }} onClick={() => {
                            this.handleEnDisBank(row.name, row.id, 'enabled')
                          }}
                        />
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    )
  }
}

export default withRouter(bankContainer)