import React, { Component } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import Button from '@material-ui/core/Button'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import CondicionesService from '../services/condiciones.service'
import Swal from 'sweetalert2'
import CancelIcon from '@material-ui/icons/Cancel'
import './ingresos.css'


class Ingresos extends Component {
  constructor(props) {
    super(props)
    this.state = {
      condiciones: [],
      openModal: false
    }

    this.limite = React.createRef()
    this.ingresos = React.createRef()
  }

  async componentDidMount() {
    await this.getIngresos()
  }

  getIngresos = async () => {
    let condicionesService = new CondicionesService()
    let condiciones = await condicionesService.getCondiciones(localStorage.getItem('userlogged'))

    this.setState({
      condiciones
    })
  }

  cancelIngresos = async () => {
    let condicionesService = new CondicionesService()

    let condiciones = await condicionesService.modifyIngresos(0, 0, localStorage.getItem('userlogged'))
    if (condiciones.data) {
      Swal.fire({
        title: `Exito`,
        text: `Los ingresos y limite fueron modificados`,
        icon: 'success',
        showConfirmButton: false
      })
    } else {
      Swal.fire({
        title: `Error`,
        text: `Ocurrio un error intente de nuevo`,
        icon: 'error',
        showConfirmButton: false
      })
    }
    await this.getIngresos()
  }

  modifyIngresos = async () => {
    let condicionesService = new CondicionesService()
    if (this.limite.current.value && this.ingresos.current.value) {
      let condiciones = await condicionesService.modifyIngresos(this.limite.current.value, this.ingresos.current.value, localStorage.getItem('userlogged'))
      if (condiciones.data) {
        Swal.fire({
          title: `Exito`,
          text: `Los ingresos y limite fueron modificados`,
          icon: 'success',
          showConfirmButton: false
        })
      } else {
        Swal.fire({
          title: `Error`,
          text: `Ocurrio un error intente de nuevo`,
          icon: 'error',
          showConfirmButton: false
        })
      }
    } else {
      Swal.fire({
        title: `Error`,
        text: `Ocurrio un error intente de nuevo`,
        icon: 'error',
        showConfirmButton: false
      })
    }

    await this.getIngresos()
  }

  render() {
    return (
      <Grid item xs={9} style={{ margin: '0 0 20px', fontFamily: 'Slightpay-light' }}>
        <h3 style={{ marginTop: '15px' }}>Condición de ingresos</h3>
        <div style={{ display: 'flex', marginBottom: '15px' }}>
          <TextField
            id="outlined-read-only-input"
            label="Limite"
            variant="outlined"
            style={{ width: '20%', marginRight: '15px' }}
            InputLabelProps={{ shrink: true }}
            inputRef={this.limite}
          />
          <TextField
            id="outlined-read-only-input"
            label="Ingresos"
            variant="outlined"
            style={{ width: '20%', marginRight: '15px' }}
            InputLabelProps={{ shrink: true }}
            inputRef={this.ingresos}
          />
          <Button variant="contained" style={{ backgroundColor: "rgb(26, 76, 205)", color: "white", fontSize: '12px', fontFamily: 'Slightpay-bold' }} onClick={() => { this.modifyIngresos() }}>Modificar</Button>
        </div>
        {
          this.state.condiciones.length !== 0 ?
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead style={{ backgroundColor: 'rgb(26, 76, 205)', fontSize: '8px' }}>
                <TableRow>
                  <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">Conteo</TableCell>
                  <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">Limite</TableCell>
                  <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">Ingresos</TableCell>
                  <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.condiciones.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{row.conteo}</TableCell>
                    <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">{row.limite}</TableCell>
                    <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">{row.ingresos}</TableCell>
                    <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">
                      <div style={{ display: 'flex', justifyContent: 'space-around', cursor: 'pointer' }} onClick={() => { this.cancelIngresos() }}>
                        <CancelIcon />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer> :
          <p style={{ textAlign: 'center' }}>No hay ninguna condición, agregue alguna.</p>
        }
      </Grid>
    )
  }
}

export { Ingresos as default }
