import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import GroupIcon from '@material-ui/icons/Group'
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import SettingsIcon from '@material-ui/icons/Settings';
import logo from '../assets/logo.png'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import ListIcon from '@material-ui/icons/List'
import BuildIcon from '@material-ui/icons/Build'
import AssessmentIcon from '@material-ui/icons/Assessment'
import '../App.css'
import './sidenav.css'

class Sidenav extends Component {
  state = {
    selected: 1
  }

  itemSelected = (selected) => {
    this.setState({ selected })
  }

  render() {
    return (
      <div className='sidebar' style={{ flex: '0 0 15%', width: '195px', backgroundColor: 'rgb(28, 28, 28)', height: '100vh', fontSize: '18px', display: 'flex', flexDirection: 'column', position: 'absolute', boxShadow: '3px 0px 5px 0px rgba(0,0,0,0.75)' }}>
        <div className='item' style={{ padding: '15px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', color: 'white', textDecoration: 'blink' }}>
            <img src={logo} width='100' height='30' alt='logo-slight' />
          </div>
        </div>
        {
          localStorage.getItem('type') === 'user' ?
            <div style={{ fontFamily: 'Slightpay-light' }}>
              <Link to='/home' style={{ color: 'white', textDecoration: 'blink' }}>
                <div id='1' className='item cardnav' style={{ padding: '15px', fontFamily: this.state.selected === 1 ? 'Slightpay-bold' : 'Slightpay-light', backgroundColor: this.state.selected === 1 ? 'rgb(26, 76, 205)' : null }} onClick={() => { this.itemSelected(1) }}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'white', textDecoration: 'blink' }}>
                    <GroupIcon />
                    <p className='novisible' style={{ margin: '3px 15px', fontSize: '15px' }}>Usuarios</p>
                  </div>
                </div>
              </Link>
              <Link to='/slightpay' style={{ color: 'white', textDecoration: 'blink' }}>
                <div id='2' className='item cardnav' style={{ padding: '15px', fontFamily: this.state.selected === 2 ? 'Slightpay-bold' : 'Slightpay-light', backgroundColor: this.state.selected === 2 ? 'rgb(26, 76, 205)' : null }} onClick={() => { this.itemSelected(2) }}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'white', textDecoration: 'blink' }}>
                    <ListIcon />
                    <p className='novisible' style={{ margin: '3px 15px', fontSize: '15px' }}>Slightpay</p>
                  </div>
                </div>
              </Link>
              <Link to='/cp' style={{ color: 'white', textDecoration: 'blink' }}>
                <div id='3' className='item cardnav' style={{ padding: '15px', fontFamily: this.state.selected === 3 ? 'Slightpay-bold' : 'Slightpay-light', backgroundColor: this.state.selected === 3 ? 'rgb(26, 76, 205)' : null }} onClick={() => { this.itemSelected(3) }}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'white', textDecoration: 'blink' }}>
                    <NotInterestedIcon />
                    <p className='novisible' style={{ margin: '3px 15px', fontSize: '15px' }}>CP Restringidos</p>
                  </div>
                </div>
              </Link>
              <Link to='/ingresos' style={{ color: 'white', textDecoration: 'blink' }}>
                <div id='4' className='item cardnav' style={{ padding: '15px', fontFamily: this.state.selected === 4 ? 'Slightpay-bold' : 'Slightpay-light', backgroundColor: this.state.selected === 4 ? 'rgb(26, 76, 205)' : null }} onClick={() => { this.itemSelected(4) }}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'white', textDecoration: 'blink' }}>
                    <AttachMoneyIcon />
                    <p className='novisible' style={{ margin: '3px 15px', fontSize: '15px' }}>Ingresos BC</p>
                  </div>
                </div>
              </Link>
              <Link to='/algoritmo' style={{ color: 'white', textDecoration: 'blink' }}>
                <div id='5' className='item cardnav' style={{ padding: '15px', fontFamily: this.state.selected === 5 ? 'Slightpay-bold' : 'Slightpay-light', backgroundColor: this.state.selected === 5 ? 'rgb(26, 76, 205)' : null }} onClick={() => { this.itemSelected(5) }}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'white', textDecoration: 'blink' }}>
                    <BuildIcon />
                    <p className='novisible' style={{ margin: '3px 15px', fontSize: '15px' }}>Algoritmo</p>
                  </div>
                </div>
              </Link>
              {/* <Link to='/agregar-banco' style={{ color: 'white', textDecoration: 'blink' }}>
                <div id='6' className='item cardnav' style={{ padding: '15px', fontFamily: this.state.selected === 6 ? 'Slightpay-bold' : 'Slightpay-light', backgroundColor: this.state.selected === 6 ? 'rgb(26, 76, 205)' : null }} onClick={() => { this.itemSelected(6) }}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'white', textDecoration: 'blink' }}>
                    <AccountBalanceIcon />
                    <p className='novisible' style={{ margin: '3px 15px', fontSize: '15px' }}>Bancos</p>
                  </div>
                </div>
              </Link>
              <Link to='/reporte-bc' style={{ color: 'white', textDecoration: 'blink' }}>
                <div id='7' className='item cardnav' style={{ padding: '15px', fontFamily: this.state.selected === 7 ? 'Slightpay-bold' : 'Slightpay-light', backgroundColor: this.state.selected === 7 ? 'rgb(26, 76, 205)' : null }} onClick={() => { this.itemSelected(7) }}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'white', textDecoration: 'blink' }}>
                    <AssessmentIcon />
                    <p className='novisible' style={{ margin: '3px 15px', fontSize: '15px' }}>Reportes BC</p>
                  </div>
                </div>
              </Link> */}
              <Link to='/user-admin' style={{ color: 'white', textDecoration: 'blink' }}>
                <div id='8' className='item cardnav' style={{ padding: '15px', fontFamily: this.state.selected === 7 ? 'Slightpay-bold' : 'Slightpay-light', backgroundColor: this.state.selected === 8 ? 'rgb(26, 76, 205)' : null }} onClick={() => { this.itemSelected(8) }}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'white', textDecoration: 'blink' }}>
                    <SettingsIcon />
                    <p className='novisible' style={{ margin: '3px 15px', fontSize: '15px' }}>Administracion</p>
                  </div>
                </div>
              </Link>
              <Link to={`/login?userlogin=false`} style={{ color: 'white', textDecoration: 'blink' }} onClick={() => {
                this.props.changeLog(false)
                localStorage.removeItem('userlogged')
                localStorage.removeItem('user')
              }}>
                <div id='100' className='item' style={{ padding: '15px', position: 'absolute', bottom: '5px', fontFamily: this.state.selected === 100 ? 'Slightpay-bold' : 'Slightpay-light', backgroundColor: this.state.selected === 100 ? 'rgb(26, 76, 205)' : null }} onClick={() => { this.itemSelected(100) }}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'white', textDecoration: 'blink' }}>
                    <MeetingRoomIcon />
                    <p className='novisible' style={{ margin: '3px 15px', fontSize: '15px' }}>Cerrar sesión</p>
                  </div>
                </div>
              </Link>
            </div>
            :
    <div>
      <Link to='/administracion' style={{ color: 'white', textDecoration: 'blink' }}>
        <div id='1' className='item cardnav' style={{ padding: '15px', fontFamily: this.state.selected === 1 ? 'Slightpay-bold' : 'Slightpay-light', backgroundColor: this.state.selected === 1 ? 'rgb(26, 76, 205)' : null }} onClick={() => { this.itemSelected(1) }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'white', textDecoration: 'blink' }}>
            <SupervisorAccountIcon />
            <p className='novisible' style={{ margin: '3px 15px', fontSize: '15px' }}>Administracion</p>
          </div>
        </div>
      </Link>
      <Link to='/reporte' style={{ color: 'white', textDecoration: 'blink' }}>
        <div id='2' className='item cardnav' style={{ padding: '15px', fontFamily: this.state.selected === 2 ? 'Slightpay-bold' : 'Slightpay-light', backgroundColor: this.state.selected === 2 ? 'rgb(26, 76, 205)' : null }} onClick={() => { this.itemSelected(2) }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'white', textDecoration: 'blink' }}>
            <AssessmentIcon />
            <p className='novisible' style={{ margin: '3px 15px', fontSize: '15px' }}>Reportes</p>
          </div>
        </div>
      </Link>
      <Link to='/algoritmo' style={{ color: 'white', textDecoration: 'blink' }}>
        <div id='3' className='item cardnav' style={{ padding: '15px', fontFamily: this.state.selected === 3 ? 'Slightpay-bold' : 'Slightpay-light', backgroundColor: this.state.selected === 3 ? 'rgb(26, 76, 205)' : null }} onClick={() => { this.itemSelected(3) }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'white', textDecoration: 'blink' }}>
            <BuildIcon />
            <p className='novisible' style={{ margin: '3px 15px', fontSize: '15px' }}>Algoritmo</p>
          </div>
        </div>
      </Link>
      <Link to='/ingresos' style={{ color: 'white', textDecoration: 'blink' }}>
        <div id='4' className='item cardnav' style={{ padding: '15px', fontFamily: this.state.selected === 4 ? 'Slightpay-bold' : 'Slightpay-light', backgroundColor: this.state.selected === 4 ? 'rgb(26, 76, 205)' : null }} onClick={() => { this.itemSelected(4) }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'white', textDecoration: 'blink' }}>
            <AttachMoneyIcon />
            <p className='novisible' style={{ margin: '3px 15px', fontSize: '15px' }}>Ingresos BC</p>
          </div>
        </div>
      </Link>
      <Link to='/cp' style={{ color: 'white', textDecoration: 'blink' }}>
        <div id='5' className='item cardnav' style={{ padding: '15px', fontFamily: this.state.selected === 5 ? 'Slightpay-bold' : 'Slightpay-light', backgroundColor: this.state.selected === 5 ? 'rgb(26, 76, 205)' : null }} onClick={() => { this.itemSelected(5) }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'white', textDecoration: 'blink' }}>
            <NotInterestedIcon />
            <p className='novisible' style={{ margin: '3px 15px', fontSize: '15px' }}>CP Restringidos</p>
          </div>
        </div>
      </Link>
      <Link to={`/login?userlogin=false`} style={{ color: 'white', textDecoration: 'blink' }} onClick={() => {
        this.props.changeLog(false)
        localStorage.removeItem('userlogged')
        localStorage.removeItem('user')
      }}>
        <div id='6' className='item' style={{ padding: '15px', fontFamily: this.state.selected === 6 ? 'Slightpay-bold' : 'Slightpay-light', position: 'absolute', bottom: '5px', backgroundColor: this.state.selected === 6 ? 'rgb(26, 76, 205)' : null }} onClick={() => { this.itemSelected(6) }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'white', textDecoration: 'blink' }}>
            <MeetingRoomIcon />
            <p className='novisible' style={{ margin: '3px 15px', fontSize: '15px' }}>Cerrar sesión</p>
          </div>
        </div>
      </Link>
    </div>
  }
        {/* <Link to='/tickets' style={{ color: 'white', textDecoration: 'blink' }}>
          <div id='2' className='item' style={{ padding: '15px', backgroundColor: this.state.selected === 2 ? '#f16839' : null }} onClick={() => { this.itemSelected(2) }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'white', textDecoration: 'blink' }}>
              <HistoryIcon />
              <p className='novisible' style={{ margin: '3px 15px', fontSize: '15px' }}>Historial Ticket</p>
            </div>
          </div>
        </Link>

        <Link to='/administration' style={{ color: 'white', textDecoration: 'blink' }}>
          <div id='3' className='item' style={{ padding: '15px', backgroundColor: this.state.selected === 3 ? '#f16839' : null }} onClick={() => { this.itemSelected(3) }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'white', textDecoration: 'blink' }}>
              <SupervisorAccountIcon />
              <p className='novisible' style={{ margin: '3px 15px', fontSize: '15px' }}>Administración</p>
            </div>
          </div>
        </Link> :

        <Link to='/logeo' style={{ color: 'white', textDecoration: 'blink' }}>
          <div id='4' className='item' style={{ padding: '15px', backgroundColor: this.state.selected === 4 ? '#f16839' : null }} onClick={() => { this.itemSelected(4) }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'white', textDecoration: 'blink' }}>
              <Launch />
              <p className='novisible' style={{ margin: '3px 15px', fontSize: '15px' }}>Logeo</p>
            </div>
          </div>
        </Link>
        <Link to='/reports' style={{ color: 'white', textDecoration: 'blink' }}>
          <div id='5' className='item' style={{ padding: '15px', backgroundColor: this.state.selected === 5 ? '#f16839' : null }} onClick={() => { this.itemSelected(5) }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'white', textDecoration: 'blink' }}>
              <ImportContactsIcon />
              <p className='novisible' style={{ margin: '3px 15px', fontSize: '15px' }}>Reportes</p>
            </div>
          </div>
        </Link> */}
{/* 
        <div style={{ paddingBottom: '5px', display: 'flex', justifyContent: 'center', alignItems: 'flex-end', flex: '1' }}>
          <img className='banImage' src={ban2} width='60' height='60' />
        </div> */}
      </div >
    )
  }
}

export { Sidenav as default }
