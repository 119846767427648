import React, { Component } from 'react';
import * as firebase from 'firebase'
import './App.css';
import AppView from './views/App.view'

class App extends Component {
  state = {
    logged: false,
    user: {}
  }

  componentDidMount() {
    var firebaseConfig = {
      apiKey: "AIzaSyCsdF478wIdcdtSCEzmTWPwq6nBYnn5TTE",
      authDomain: "slightpay-score.firebaseapp.com",
      databaseURL: "https://slightpay-score.firebaseio.com",
      projectId: "slightpay-score",
      storageBucket: "slightpay-score.appspot.com",
      messagingSenderId: "212306984875",
      appId: "1:212306984875:web:f56a15389b9b9eaf665cee",
      measurementId: "G-CWJMCSW4VM"
    };
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
  }

  changeLog = (logged, user) => { this.setState({ logged, user }) }

  render() {
    return (
      <AppView changeLog={this.changeLog} logged={this.state.logged} user={this.state.user} />
    )
  }
}

export default App;
