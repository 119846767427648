import requestPromise from 'request-promise'
import config from './config'

let { URL_API } = config


class ReglasService {
  async getRules(email) {
    let token = await this.getToken()

    let response = await requestPromise({
      method: 'GET',
      url: `${URL_API}/api/v1/get-rules?email=${email}`,
      headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      json: true
    })

    return response
  }

  async getICC() {
    let token = await this.getToken()

    let response = await requestPromise({
      method: 'GET',
      url: `${URL_API}/api/v1/get-icc`,
      headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      json: true
    })

    return response
  }
  async getBC() {
    let token = await this.getToken()

    let response = await requestPromise({
      method: 'GET',
      url: `${URL_API}/api/v1/get-bc`,
      headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      json: true
    })

    return response
  }

  async insertRule(body) {
    let token = await this.getToken()

    let response = await requestPromise({
      method: 'POST',
      url: `${URL_API}/api/v1/insert-rule`,
      headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body,
      json: true
    })

    return response
  }

  async deleteRule(id) {
    let token = await this.getToken()

    let response = await requestPromise({
      method: 'POST',
      url: `${URL_API}/api/v1/delete-rule`,
      headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: {
        id
      },
      json: true
    })

    return response
  }

  async getToken() {
    let response = await requestPromise({
      method: 'POST',
      url: 'https://finerioapi.auth0.com/oauth/token',
      headers: {
        'content-type': 'application/json'
      },
      body: {
        "grant_type": "client_credentials",
        "client_id": "J5LNTaQmKHwUxLPcr1KDslxB4Y3ozroI",
        "client_secret": "PY4fGLLhqem9DmSCXaVi9VPONMeNLVB5E-6s7mdaXoYH4htaNcXvWJ02jY03du1i",
        "audience": "https://slightpay.com"
      },
      json: true
    })

    return response.access_token
  }
}

export { ReglasService as default }