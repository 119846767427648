import React, { Component } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import Button from '@material-ui/core/Button'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import UserService from '../services/user.service'
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox';

import Modal from '../components/modal/modal'



class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      users: [],
      openModal: false,
      aceptados: false,
      rechazados: false,
      observacion: false
    }

    this.nombre = React.createRef()
    this.apellidoPaterno = React.createRef()
    this.apellidoMaterno = React.createRef()
  }

  async componentDidMount() {
    await this.getUsers()
    
  }

  getUsers = async (body) => {
    let userService = new UserService()
    let users = await userService.getUsers(body)
    console.log(users)
    this.setState({
      users
    })

  }

  getUsersByName = async () => {
    let userService = new UserService()

    let nombre = this.nombre.current.value
    let apellidoPaterno = this.apellidoPaterno.current.value
    let apellidoMaterno = this.apellidoMaterno.current.value

    let users = await userService.getUsersByName(nombre, apellidoPaterno, apellidoMaterno)

    this.setState({ users })
  }

  handleModal = async (openModal, buscar) => {
    if (buscar) {
      let obj = {}

      if (this.state.observacion) {
        obj.observacion = true
      }

      if (this.state.aceptados) {
        obj.aceptados = true
      }

      if (this.state.rechazados) {
        obj.rechazados = true
      }

      await this.getUsers(obj)
    }

    this.setState({ openModal })
  }

  // Material UI Function
  handleChange = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.checked })
  }


  render() {
    // Necesito en la base de datos agregar las condiciones para que un score pase y asi poder obtenerlos
    // Por lo pronto los tengo puestos aqui
    return (
      <Grid container style={{ justifyContent: 'center', fontFamily: 'Slightpay-light' }}>
        <Modal openModal={this.state.openModal} title='Busqueda avanzada' handleModal={this.handleModal} style={{ fontFamily: 'Slightpay-light' }}>
          <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexWrap: 'wrap', padding: '20px' }}>
            <div style={{ flex: '1 1 100%', justifyContent: 'center', display: 'flex' }}>
              <FormControl component="fieldset" >
                <FormLabel component="legend" style={{ fontFamily: 'Slightpay-bold' }}>Seleccione los filtros</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={this.state.aceptados} onChange={this.handleChange} name="aceptados" />}
                    label="Aceptados"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={this.state.rechazados} onChange={this.handleChange} name="rechazados" />}
                    label="Rechazados"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={this.state.observacion} onChange={this.handleChange} name="observacion" />}
                    label="En observacion"
                  />
                </FormGroup>
              </FormControl>
            </div>
            <Button variant="contained" style={{ backgroundColor: "rgb(26, 76, 205)", color: "white", fontSize: '12px', fontFamily: 'Slightpay-bold' }} onClick={() => { this.handleModal(false, true) }}>Buscar</Button>
          </div>
        </Modal>
        <Grid item xs={9} style={{ padding: '0 0 25px 0' }}>
          <h3>Lista de usuarios</h3>
          <Grid container style={{ justifyContent: 'center', margin: '0 0 20px' }}>
            <Grid item xs={3}>
              <TextField
                id="outlined-read-only-input"
                label="Nombre"
                variant="outlined"
                style={{ width: '90%' }}
                inputRef={this.nombre}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="outlined-read-only-input"
                label="Ap Paterno"
                variant="outlined"
                style={{ width: '90%' }}
                inputRef={this.apellidoPaterno}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="outlined-read-only-input"
                label="Ap Materno"
                variant="outlined"
                style={{ width: '90%' }}
                inputRef={this.apellidoMaterno}
              />
            </Grid>
            <Grid item xs={3} style={{ justifyContent: 'space-around', alignItems: 'center', display: 'flex' }}>
              <Button variant="contained" style={{ backgroundColor: "rgb(26, 76, 205)", color: "white", fontSize: '12px', marginBottom: '5px', fontFamily: 'Slightpay-bold' }} onClick={() => { this.getUsersByName() }}>Buscar</Button>
              <Button variant="contained" style={{ backgroundColor: "rgb(26, 76, 205)", color: "white", fontSize: '12px', marginBottom: '5px', fontFamily: 'Slightpay-bold' }} onClick={() => { this.handleModal(true) }}>Filtros</Button>
            </Grid>
          </Grid>

          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead style={{ backgroundColor: 'rgb(26, 76, 205)', fontSize: '8px' }}>
                <TableRow>
                  <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }}>Nombre</TableCell>
                  <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">Direccion</TableCell>
                  <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">Edad</TableCell>
                  <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">CP</TableCell>
                  <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">BC Score</TableCell>
                  <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">ICC y BC</TableCell>
                  <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">Status</TableCell>
                  <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">Observacion</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.users.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} component="th" scope="row">
                      {`${row.nombre.toUpperCase()} ${row.apellido_paterno.toUpperCase()} ${row.apellido_materno.toUpperCase()}`}
                    </TableCell>
                    <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{`${row.direccion.toUpperCase()} ${row.ciudad.toUpperCase()}, ${row.estado.toUpperCase()}`}</TableCell>
                    <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{row.edad}</TableCell>
                    <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{row.cp}</TableCell>
                    <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{row.score.valor_score}</TableCell>
                    <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">
                      <p>{`${row.score.status_icc.valor}`}</p>
                      <p>{`${row.score.status_score.valor}`}</p></TableCell>
                    <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{row.status.valor}</TableCell>
                    {row.observacion ?
                      <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold', color: '#44b944' }} align="center">{'Si'}</TableCell> :
                      <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold', color: '#da7a7a' }} align="center">{'No'}</TableCell>
                    }
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    )
  }
}

export { Home as default }
