import requestPromise from 'request-promise'
import config from './config'

let { URL_API } = config

class CPService {
  async getCpAuth (email) {
    let token = await this.getToken()

    let response = await requestPromise({
      method: 'GET',
      url: `${URL_API}/api/v1/get-cp-auth?email=${email}`,
      headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })

    return JSON.parse(response)
  }

  async insertCpAuth (idCP, limite, email) {
    let token = await this.getToken()

    let response = await requestPromise({
      method: 'POST',
      url: `${URL_API}/api/v1/insert-cp-auth`,
      headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: {
        idCP,
        limite,
        email
      },
      json: true
    })

    return response
  }

  async insertCpRest (numero, colonia, email) {
    let token = await this.getToken()

    let response = await requestPromise({
      method: 'POST',
      url: `${URL_API}/api/v1/insert-cp-rest`,
      headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: {
        numero,
        colonia,
        email
      },
      json: true
    })

    return response
  }

  async getCps (email) {
    let token = await this.getToken()

    let response = await requestPromise({
      method: 'GET',
      url: `${URL_API}/api/v1/get-cp-rest?email=${email}`,
      headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })

    return JSON.parse(response)
  }

  async deleteCp (id) {
    let token = await this.getToken()

    let response = await requestPromise({
      method: 'POST',
      url: `${URL_API}/api/v1/delete-cp`,
      headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: {
        id,
      },
      json: true
    })

    return response
  }

  async deleteCpAuth (id) {
    let token = await this.getToken()

    let response = await requestPromise({
      method: 'POST',
      url: `${URL_API}/api/v1/delete-cp-auth`,
      headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: {
        id,
      },
      json: true
    })

    return response
  }

  async resetCpAuth (id) {
    let token = await this.getToken()

    let response = await requestPromise({
      method: 'POST',
      url: `${URL_API}/api/v1/reset-cp-auth`,
      headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: {
        id,
      },
      json: true
    })

    return response
  }

  async getToken() {
    let response = await requestPromise({
      method: 'POST',
      url: 'https://finerioapi.auth0.com/oauth/token',
      headers: {
        'content-type': 'application/json'
      },
      body: {
        "grant_type": "client_credentials",
        "client_id": "J5LNTaQmKHwUxLPcr1KDslxB4Y3ozroI",
        "client_secret": "PY4fGLLhqem9DmSCXaVi9VPONMeNLVB5E-6s7mdaXoYH4htaNcXvWJ02jY03du1i",
        "audience": "https://slightpay.com"
      },
      json: true
    })

    return response.access_token
  }
}

export { CPService as default }
