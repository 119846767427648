import React from 'react'
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { Sidenav, Main, Login } from '../containers'

let AppView = (props) => {
    // let history = createBrowserHistory()
    // console.log(history)

    // let changeRoute = (route) => {
    //     history.push(route)
    // }

    // changeRoute('/login')
    console.log(props)
    return (
        <div className="App" style={{ height: "100vh", fontFamily: 'Roboto, Helvetica, Arial, sans-serif' }}>
            <Router>
                {props.logged ? <div style={{ display: "flex", flexWrap: "wrap", height: '100%', overflow: "hidden" }}>
                    <Sidenav changeLog={props.changeLog} user={props.user} />
                    <div style={{ width: '174px' }}></div>
                    <Main user={props.user} /> 
                </div> :
                    (<Switch>
                        <Route>
                            <Login changeLog={props.changeLog} path='/login' />
                        </Route>
                    </Switch>)}
                <Redirect to='/login' />
            </Router>
        </div >
    )
}

export { AppView as default }
