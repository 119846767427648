import requestPromise from 'request-promise'
import config from './config'

let { URL_API } = config

class UserService {

  async getAllAdmins(email) {
    let token = await this.getToken()

    let response = await requestPromise({
      method: 'GET',
      url: `${URL_API}/api/v1/get-user-admins`,
      headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })

    return JSON.parse(response)
  }

  async getReports(email) {
    let token = await this.getToken()

    let response = await requestPromise({
      method: 'GET',
      url: `${URL_API}/api/v1/get-reports?email=${email}`,
      headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })

    return JSON.parse(response)
  }

  async getAllBanks() {
    let token = await this.getToken()

    let response = await requestPromise({
      method: 'GET',
      url: `${URL_API}/api/v1/get-banks-slightpay`,
      headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })

    return JSON.parse(response)
  }

  async addAdmin(name, email) {
    let token = await this.getToken()

    let response

    try {
      response = await requestPromise({
        method: 'POST',
        url: `${URL_API}/api/v1/add-admin`,
        headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: {
          name, email
        },
        json: true
      })
    } catch (e) {
      return { status: 'error', mensaje: e }
    }

    return response
  }

  async deleteAdmin(id) {
    let token = await this.getToken()

    let response

    try {
      response = await requestPromise({
        method: 'POST',
        url: `${URL_API}/api/v1/delete-admin`,
        headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: {
          id
        },
        json: true
      })
    } catch (e) {
      return { status: 'error', mensaje: e }
    }

    return response
  }

  async getToken() {
    let response = await requestPromise({
      method: 'POST',
      url: `https://finerioapi.auth0.com/oauth/token`,
      headers: {
        'content-type': 'application/json'
      },
      body: {
        "grant_type": "client_credentials",
        "client_id": "J5LNTaQmKHwUxLPcr1KDslxB4Y3ozroI",
        "client_secret": "PY4fGLLhqem9DmSCXaVi9VPONMeNLVB5E-6s7mdaXoYH4htaNcXvWJ02jY03du1i",
        "audience": "https://slightpay.com"
      },
      json: true
    })

    return response.access_token
  }
}

export default UserService