import requestPromise from 'request-promise'
import config from './config'

let { URL_API } = config

class UserService {
  async getUsers (body) {
    // let token = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ik5UWkZRamhEUWtKRVFUaENRVFE1TmpCQk5UQTNOREpCTUROR01qUXlOall6TkVZMk1UbEROdyJ9.eyJpc3MiOiJodHRwczovL2ZpbmVyaW9hcGkuYXV0aDAuY29tLyIsInN1YiI6Iko1TE5UYVFtS0h3VXhMUGNyMUtEc2x4QjRZM296cm9JQGNsaWVudHMiLCJhdWQiOiJodHRwczovL3NsaWdodHBheS5jb20iLCJpYXQiOjE1ODc5OTk5MjIsImV4cCI6MTU4ODA4NjMyMiwiYXpwIjoiSjVMTlRhUW1LSHdVeExQY3IxS0RzbHhCNFkzb3pyb0kiLCJndHkiOiJjbGllbnQtY3JlZGVudGlhbHMifQ.i4-CP4DEtbzYCu1PjcVETHREMggugQmBXSZlMa_p6R-hFQpt__SZqpZikr2hT0C4WgNv3m1Jgm0Xq2rZ6NVxhYTOUEHwmYzc_CfYqr1Wj48_yQ_luX85HPrvznL7_ycKF8-Dh0XTmEe6YaO0kmOOfEWP5ZEB8PwpiarJNIZEfYnPjGaxdhqG0lS1JL2Q_PrI2vfY0UAyT1o2tJ9-NGNA_JryPymAU-EeHj7snG9T-ZUdmXjJ39-FqL5ZMZO155_ACwMFNx56C2itsik2bLbz5Kg-6f5adclmwUAz0psfvQLpq9Zf-MXl7DmAbIT3xVXTRAiRXkIkTe18fOkaXRMzAA'
    let token = await this.getToken()

    let response = await requestPromise({
      method: 'POST',
      url: `${URL_API}/api/v1/get-users`,
      headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body,
      json: true
    })

    return response.reverse()
  }

  async getUsersByName (nombre, apellidoPaterno, apellidoMaterno) {
    let token = await this.getToken()

    let response = await requestPromise({
      method: 'POST',
      url: `${URL_API}/api/v1/pruebas`,
      headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: {
        "nombre": nombre,
        "apellido_paterno": apellidoPaterno,
        "apellido_materno": apellidoMaterno
      },
      json: true
    })

    return response
  }

  async getToken() {
    let response = await requestPromise({
      method: 'POST',
      url: 'https://finerioapi.auth0.com/oauth/token',
      headers: {
        'content-type': 'application/json'
      },
      body: {
        "grant_type": "client_credentials",
        "client_id": "J5LNTaQmKHwUxLPcr1KDslxB4Y3ozroI",
        "client_secret": "PY4fGLLhqem9DmSCXaVi9VPONMeNLVB5E-6s7mdaXoYH4htaNcXvWJ02jY03du1i",
        "audience": "https://slightpay.com"
      },
      json: true
    })

    return response.access_token
  }

  async getFile() {
    let token = await this.getToken()

    let response = await requestPromise({
      method: 'GET',
      url: `${URL_API}/api/v1/download`,
      headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      json: true
    })

    return response
  }
}

export { UserService as default }
