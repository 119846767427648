import React from 'react'
import Box from '@material-ui/core/Box';

const Error = ({mensajeError}) => {
    
    return (
        <Box>
            <h2>Ops! Ha ocurrido un Error</h2>
            {/* <p>{mensajeError}</p> */}
        </Box>
    )
}

export default Error
