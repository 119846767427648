import React, { Component } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import BankService from '../services/bank.service'
import CircularProgress from '@material-ui/core/CircularProgress'
import './font.css'


class AdminBank extends Component {
  constructor(props) {
    super(props)
    this.state = {
      information: {},
      openModal: false,
      loading: true
    }

    this.limite = React.createRef()
    this.ingresos = React.createRef()
  }

  async componentDidMount() {
    await this.getInformation()
  }

  async getInformation() {
    setTimeout(async () => {
      let email = localStorage.getItem('userlogged')
      let bankService = new BankService()

      let responseBankService = await bankService.getInformation(email)

      console.log(responseBankService)
      this.setState({ information: responseBankService, loading: false })
    }, 2000)
  }

  render() {
    return (
      <Grid item xs={9} style={{ margin: '0 0 20px', fontFamily: 'Slightpay-light' }}>
        <h3 style={{ marginTop: '15px' }}>Administracion del Banco</h3>
        {
          !this.state.loading ?
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead style={{ backgroundColor: 'rgb(26, 76, 205)', fontSize: '8px' }}>
                  <TableRow>
                    <TableCell style={{ color: 'white', fontSize: '14px', fontFamily: 'Slightpay-bold' }} align="left">Tasa de interés</TableCell>
                    <TableCell style={{ color: 'white', fontSize: '14px', fontFamily: 'Slightpay-bold' }} align="center">Capital</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={this.state.information.id}>
                    <TableCell style={{ fontSize: '14px', fontFamily: 'Slightpay-bold' }} align="left">{this.state.information.interest_rate} %</TableCell>
                    <TableCell style={{ fontSize: '14px', fontFamily: 'Slightpay-bold' }} align="center">$ {this.state.information.capital}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            :
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <CircularProgress />
            </div>
        }
      </Grid>
    )
  }
}

export { AdminBank as default }
