import React, { useState, useRef, useEffect } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import Button from '@material-ui/core/Button'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import AdminService from '../services/userAdmin.service'
import CancelIcon from '@material-ui/icons/Cancel'
import PublishIcon from '@material-ui/icons/Publish'
import * as firebase from 'firebase'
import Modal from '../components/modal/modal'
import Swal from 'sweetalert2'

import { withRouter } from 'react-router-dom'

import './font.css'

let UserAdmin = () => {
  let [openModal, setOpenModal] = useState(0)
  let [users, setUsers] = useState([])
  let email = useRef(null)
  let name = useRef(null)

  useEffect(() => {
    getAllAdmins()
  }, [])

  /**
   * @author Brayan Salas
   * @description Method to get all banks registered on Slightpay Scoring
   * @memberof bankContainer
   */
  let getAllAdmins = async () => {
    let userService = new AdminService()

    let result = await userService.getAllAdmins()
    setUsers(result)
  }

  /**
   * @author Brayan Salas
   * @description Methdd to add/modify a bank
   * @memberof bankContainer
   */
  let addUser = async (args) => {
    let adminService = new AdminService()

    let result = ''

    try {
      console.log(result)
      if(args) {
        result = await adminService.addAdmin(name.current.value, email.current.value)
      } else {
        result = await adminService.addAdmin(name.current.value, email.current.value)
      }
    } catch (e) {
      Swal.fire({
        title: `Error`,
        text: `Ocurrio un error al registrar el admin DB`,
        icon: 'error',
        showConfirmButton: false
      })
    }

    let password = Math.random().toString(36).slice(2)
    await firebase.auth().createUserWithEmailAndPassword(email.current.value, password).catch(function (error) {
      handleModal(false)
      Swal.fire({
        title: `Error`,
        text: `Ocurrio un error al registrar el admin FB`,
        icon: 'error',
        showConfirmButton: false
      })
    })
    handleModal(false)
    getAllAdmins()
    Swal.fire({
      title: `Exito`,
      text: `Se ha agregado el nuevo admin su password es: ${password}`,
      icon: 'success',
      showConfirmButton: true
    })
  }

  /**
   * @author Brayan Salas
   * @description Method to delete an admin
   */
  let deleteAdmin = async (obj) => {
    let adminService = new AdminService()

    Swal.fire({
      title: `¿Deseas deshabilitar ${obj.name}`,
      text: 'Puedes activarlo nuevamente en este panel',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.value) {
        try {
          await adminService.deleteAdmin(obj.id)
        } catch (e) {
          Swal.fire({
            title: `Error`,
            text: `No se ha podido deshabilitar el admin ${obj.name}`,
            icon: 'error',
            showConfirmButton: false
          })
        }
        Swal.fire({
          title: `Exito`,
          text: `Se ha deshabilitado el admin ${obj.name}`,
          icon: 'success',
          showConfirmButton: false
        })
        await getAllAdmins()
      }
    })
  }

  let enableAdmin = async (obj) => {
    let adminService = new AdminService()
    Swal.fire({
      title: `¿Deseas habilitar ${obj.name}`,
      text: 'Puedes desactivarlo nuevamente en este panel',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.value) {
        try {
          await adminService.addAdmin(obj.name, obj.email)
        } catch (e) {
          Swal.fire({
            title: `Error`,
            text: `No se ha podido habilitar el admin ${obj.name}`,
            icon: 'error',
            showConfirmButton: false
          })
        }
        Swal.fire({
          title: `Exito`,
          text: `Se ha habilitad el admin ${obj.name}`,
          icon: 'success',
          showConfirmButton: false
        })
        await getAllAdmins()
      }
    })
  }

  /**
   * @author Brayan Salas
   * @description Method to handle Modal open/close
   * @memberof bankContainer
   */
  let handleModal = (openModal) => {
    setOpenModal(openModal)
  }

  return (
    <Grid item xs={9} style={{ margin: '0 0 20px', fontFamily: 'Slightpay-light' }}>
      <Modal openModal={openModal} title={'Añadir Usuario'} handleModal={handleModal}>
        <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexWrap: 'wrap', padding: '20px', width: '350px' }}>
          <TextField
            id="outlined-read-only-input"
            label="Nombre"
            variant="outlined"
            style={{ width: '90%', marginBottom: '15px' }}
            inputRef={name}
          />
          <TextField
            id="outlined-read-only-input2"
            label="Email"
            variant="outlined"
            style={{ width: '90%', marginBottom: '15px' }}
            inputRef={email}
          />
          <Button variant="contained" style={{ backgroundColor: "rgb(26, 76, 205)", color: "white", fontSize: '12px', height: '50px', marginBottom: '10px', fontFamily: 'Slightpay-bold' }} onClick={() => { addUser() }}>Agregar</Button>
        </div>
      </Modal>
      <h3>Administración de usuarios scoring</h3>
      <div style={{ textAlign: 'right' }}>
        <Button variant="contained" style={{ backgroundColor: "rgb(26, 76, 205)", color: "white", fontSize: '12px', height: '50px', marginBottom: '10px', fontFamily: 'Slightpay-bold' }} onClick={() => {
          handleModal(true)
        }}>Agregar</Button>
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead style={{ backgroundColor: 'rgb(26, 76, 205)', fontSize: '8px' }}>
            <TableRow>
              <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">ID</TableCell>
              <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">Nombre</TableCell>
              <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">Email</TableCell>
              <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((row) => (
              <TableRow key={row.id}>
                <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{row.id}</TableCell>
                <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold', cursor: 'pointer' }} align="left">{row.name}</TableCell>
                <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{row.email}</TableCell>
                <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">
                  {
                    row.active ?
                      <CancelIcon style={{ cursor: 'pointer' }} onClick={() => {
                        deleteAdmin(row)
                      }} /> :
                      <PublishIcon
                        style={{ cursor: 'pointer' }} onClick={() => {
                          enableAdmin(row)
                        }}
                      />
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
}

export default withRouter(UserAdmin)