import requestPromise from 'request-promise'
import config from './config'

let { URL_API } = config


class UserAgeService {
  async getUserAgeConfig() {
    let token = await this.getToken()

    let response = await requestPromise({
      method: 'GET',
      url: `${URL_API}/api/v1/user-age-config`,
      headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      json: true
    })

    return response
  }

  async getUsersByUserAge() {
    let token = await this.getToken()

    let response = await requestPromise({
      method: 'GET',
      url: `${URL_API}/api/v1/user-age-get-users`,
      headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      json: true
    })

    return response
  }

  async updateUserAgeConfig(body) {
    let token = await this.getToken()

    let response = await requestPromise({
      method: 'PUT',
      url: `${URL_API}/api/v1/update-user-age-config`,
      headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body,
      json: true
    })

    return response
  }

  async getToken() {
    let response = await requestPromise({
      method: 'POST',
      url: 'https://finerioapi.auth0.com/oauth/token',
      headers: {
        'content-type': 'application/json'
      },
      body: {
        "grant_type": "client_credentials",
        "client_id": "J5LNTaQmKHwUxLPcr1KDslxB4Y3ozroI",
        "client_secret": "PY4fGLLhqem9DmSCXaVi9VPONMeNLVB5E-6s7mdaXoYH4htaNcXvWJ02jY03du1i",
        "audience": "https://slightpay.com"
      },
      json: true
    })

    return response.access_token
  }
}

export { UserAgeService as default }