import React, { Component } from 'react'
import logo from '../assets/logo.png'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import PermissionsService from '../services/permissions.service'

import * as firebase from 'firebase'
import Swal from 'sweetalert2'
import './font.css'

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: false,
      loading: false
    }

    this.email = React.createRef()
    this.password = React.createRef()
  }

  componentDidMount() {
    console.log('entreeee', window.location.href)
    if (window.location.href.includes('userlogin')) {
      this.props.changeLog(false)
      localStorage.removeItem('userlogged')
      localStorage.removeItem('user')
      localStorage.removeItem('type')
    } else if (localStorage.getItem('userlogged')) {
      this.props.changeLog(true)
    }
  }

  error() {
    Swal.fire({
      title: `Error`,
      text: `El usuario o contraseña no es correcto`,
      icon: 'error',
      showConfirmButton: false
    })
    this.changeError(true)
    this.changeLoading(false)
  }

  changeError = (error) => { this.setState({ error }) }

  changeLoading = (loading) => { this.setState({ loading }) }

  signIn = async (user, password) => {
    let permissionsService = new PermissionsService()
    try {
      let response = await firebase.auth().signInWithEmailAndPassword(user, password)
      if (response.user) {
        localStorage.setItem('userlogged', response.user.email)
        
        let permissions = await permissionsService.getPermissions(user)
        console.log(permissions)

        permissions.result ? localStorage.setItem('type', 'bank') : localStorage.setItem('type', 'user')

        await this.props.changeLog(true, '')
      }
    } catch (e) {
      console.log('ojo',e);
      this.error()
    }
  }

  render() {
    return (
      <div style={{ flex: '0 0 15%', height: '100', fontSize: '18px', display: 'flex', flexDirection: 'column' }}>
        <div style={{ backgroundColor: 'black', color: 'white', width: '100%', height: '75px', display: 'flex', alignItems: 'center', boxShadow: '0px 1px 16px 2px rgba(0,0,0,0.75)' }}>
          <img src={logo} width='110' height='30' style={{ paddingLeft: '10px' }} alt='slightpay-logo-sc' />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '79vh' }}>
          {
            this.state.loading ? <CircularProgress style={{ color: 'rgb(26, 76, 205)' }} /> : <Card>
              <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontFamily: 'Slightpay-bold' }}>
                <p style={{ textAlign: 'center', fontSize: '20px' }}>Inicio de sesión</p>
                <p style={{ textAlign: 'center', fontSize: '13px' }}>Utilice las credenciales proporcionadas por un administrador</p>
                <TextField
                  error={this.state.error}
                  style={{ margin: '5px', width: '400px' }}
                  required
                  id="outlined-required"
                  label="Usuario"
                  variant="outlined"
                  inputRef={this.email}
                />
                <TextField
                  error={this.state.error}
                  style={{ margin: '5px', width: '400px' }}
                  required
                  id="outlined-required"
                  label="Contraseña"
                  variant="outlined"
                  inputRef={this.password}
                  type='password'
                />
                <Button variant="contained" style={{ backgroundColor: 'rgb(26, 76, 205)', fontFamily: 'Slightpay-bold', color: 'white', width: '100px', marginTop: '20px' }} onClick={() => {
                  this.signIn(this.email.current.value, this.password.current.value)
                  this.changeLoading(true)
                }}>
                  Entrar
              </Button>
              </CardContent>
            </Card>
          }
        </div>
      </div>
    )
    
  }
}

export { Login as default }
