import React, { Component } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import Button from '@material-ui/core/Button'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField'
import CPService from '../services/cp.service'
import Swal from 'sweetalert2'
import RefreshIcon from '@material-ui/icons/Refresh';
import DeleteIcon from '@material-ui/icons/Delete'
import './cp.css'


class CP extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cpAuth: [],
      cps: [],
      idCpAuth: 0,
      openModal: false
    }

    this.limite = React.createRef()
    this.colonia = React.createRef()
    this.numero = React.createRef()
  }

  async componentDidMount() {
    await this.getCPsAuth()
    await this.getCPs()
  }

  getCPsAuth = async () => {
    let cpService = new CPService()
    let cpAuth = await cpService.getCpAuth(localStorage.getItem('userlogged'))

    this.setState({
      cpAuth
    })
  }

  getCPs = async () => {
    let cpService = new CPService()
    let cps = await cpService.getCps(localStorage.getItem('userlogged'))

    this.setState({
      cps
    })
  }

  insertNewCpAuth = async () => {
    let idCpAuth = this.state.idCpAuth
    let limite = this.limite.current.value

    if (limite.length === 0 || idCpAuth === 0) {
      Swal.fire({
        title: `Exitoso`,
        text: `Se ha realizado el ajuste con exito`,
        icon: 'success',
        showConfirmButton: false
      })
    }

    let cpService = new CPService()
    let result = await cpService.insertCpAuth(idCpAuth, Number(limite), localStorage.getItem('userlogged'))

    if (!result.data) {
      // Agregar loading
      // Mostrar que funciono con swal
      Swal.fire({
        title: `Error`,
        text: `El CP no pudo ser agregado`,
        icon: 'error',
        showConfirmButton: false
      })
    } else {
      Swal.fire({
        title: `Exito`,
        text: `CP Autorizado con limite fue agregado con exito`,
        icon: 'success',
        showConfirmButton: false
      })
      // Recargar la página
      await this.getCPsAuth()
    }
  }

  insertNewCP = async () => {
    let colonia = this.colonia.current.value
    let numero = this.numero.current.value

    if (numero.length === 0) {
      Swal.fire({
        title: `Error`,
        text: `El CP ya existe`,
        icon: 'error',
        showConfirmButton: false
      })
    }

    let cpService = new CPService()
    let result = await cpService.insertCpRest(numero, colonia, localStorage.getItem('userlogged'))

    if (!result.data) {
      // Agregar loading
      // Mostrar que funciono con swal
      // Mostrar que fallo con Swal
      Swal.fire({
        title: `Error`,
        text: `El CP ya existe`,
        icon: 'error',
        showConfirmButton: false
      })
    } else {
      Swal.fire({
        title: `Exito`,
        text: `El CP fue restringido`,
        icon: 'success',
        showConfirmButton: false
      })

      // Recargar la página
      await this.getCPs()
      await this.getCPsAuth()
    }
  }

  deleteCP = async (id) => {
    let cpService = new CPService()
    let result = await cpService.deleteCp(id)

    if (result.message === 'Ha sido eliminado con exito') {
      Swal.fire({
        title: `Exito`,
        text: `El CP fue eliminado`,
        icon: 'success',
        showConfirmButton: false
      })
      // Recargar la página
      await this.getCPs()
      await this.getCPsAuth()
    } else {
      Swal.fire({
        title: `Error`,
        text: `Ocurrio un error`,
        icon: 'error',
        showConfirmButton: false
      })
    }
  }

  deleteCPAuth = async (id) => {
    let cpService = new CPService()
    let result = await cpService.deleteCpAuth(id)

    if (result.message === 'Ha sido eliminado con exito') {
      Swal.fire({
        title: `Exito`,
        text: `El CP autorizado fue eliminado`,
        icon: 'success',
        showConfirmButton: false
      })
      await this.getCPsAuth()
    } else {
      Swal.fire({
        title: `Error`,
        text: `Ocurrio un error`,
        icon: 'error',
        showConfirmButton: false
      })
    }
  }

  resetLimitAuth = async (id) => {
    let cpService = new CPService()
    let result = await cpService.resetCpAuth(id)

    if (result.message === 'Ha sido reseteado con exito') {
      Swal.fire({
        title: `Exito`,
        text: `El CP autorizado fue reseteado`,
        icon: 'success',
        showConfirmButton: false
      })
      await this.getCPsAuth()
    } else {
      Swal.fire({
        title: `Error`,
        text: `Ocurrio un error`,
        icon: 'error',
        showConfirmButton: false
      })
    }
  }

  handleChange = (event) => {
    this.setState({ idCpAuth: event.target.value })
  }

  render() {
    return (
      <Grid item xs={9} style={{ margin: '0 0 20px', fontFamily: 'Slightpay-light' }}>
        <h3 style={{ marginTop: '15px' }}>Lista de CP restringidos</h3>
        <div style={{ display: 'flex', marginBottom: '15px' }}>
          <TextField
            id="outlined-read-only-input"
            label="Número"
            variant="outlined"
            style={{ width: '20%', marginRight: '15px' }}
            InputLabelProps={{ shrink: true }}
            inputRef={this.numero}
          />
          <TextField
            id="outlined-read-only-input"
            label="Colonia" s
            variant="outlined"
            style={{ width: '20%', marginRight: '15px' }}
            InputLabelProps={{ shrink: true }}
            inputRef={this.colonia}
          />
          <Button variant="contained" style={{ backgroundColor: "rgb(26, 76, 205)", color: "white", fontSize: '12px', fontFamily: 'Slightpay-bold' }} onClick={() => { this.insertNewCP() }}>Agregar</Button>
        </div>
        {
          this.state.cps.length !== 0 ?
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead style={{ backgroundColor: 'rgb(26, 76, 205)', fontSize: '8px' }}>
                  <TableRow>
                    <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }}>ID</TableCell>
                    <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">Numero</TableCell>
                    <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">Colonia</TableCell>
                    <TableCell style={{ color: 'white', fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.cps.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{row.id}</TableCell>
                      <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">{row.numero}</TableCell>
                      <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">{row.colonia}</TableCell>
                      <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">
                        <div style={{ display: 'flex', justifyContent: 'space-around', cursor: 'pointer' }} onClick={() => { this.deleteCP(row.id) }}>
                          <DeleteIcon />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            :
            <p style={{ textAlign: 'center' }}>No hay ninguna condición, agregue alguna.</p>
        }

        <h3>Lista de CP autorizados con limite</h3>
        <div style={{ display: 'flex', marginBottom: '15px' }}>
          <TextField
            id="outlined-read-only-input"
            label="Limite"
            variant="outlined"
            style={{ width: '20%', marginRight: '15px' }}
            inputRef={this.limite}
            InputLabelProps={{ shrink: true }}
          />
          <FormControl variant="outlined">
            <InputLabel id="demo-simple-select-outlined-label">CP</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={this.state.idCpAuth}
              onChange={(event) => { this.handleChange(event) }}
              label="CP"
            >
              {this.state.cps.map((row) => (
                <MenuItem value={row.id}>{row.numero}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button variant="contained" style={{ backgroundColor: "rgb(26, 76, 205)", color: "white", fontSize: '12px', marginLeft: '15px', fontFamily: 'Slightpay-bold' }} onClick={() => { this.insertNewCpAuth() }}>Agregar</Button>
        </div>
        {
          this.state.cpAuth.length !== 0 ?
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead style={{ backgroundColor: 'rgb(26, 76, 205)', fontSize: '8px' }}>
                  <TableRow>
                    <TableCell style={{ color: 'white', fontSize: '12px', fontFamily: 'Slightpay-bold' }}>Conteo</TableCell>
                    <TableCell style={{ color: 'white', fontSize: '12px', fontFamily: 'Slightpay-bold' }} align="left">Limite</TableCell>
                    <TableCell style={{ color: 'white', fontSize: '12px', fontFamily: 'Slightpay-bold' }} align="left">CP Permitido</TableCell>
                    <TableCell style={{ color: 'white', fontSize: '12px', fontFamily: 'Slightpay-bold' }} align="center">Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ margin: '0 0 20px' }}>
                  {this.state.cpAuth.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{row.conteo}</TableCell>
                      <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{row.limite}</TableCell>
                      <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="left">{row.cp_restringido.numero}</TableCell>
                      <TableCell style={{ fontSize: '13px', fontFamily: 'Slightpay-bold' }} align="center">
                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                          <div style={{ cursor: 'pointer' }} onClick={() => { this.resetLimitAuth(row.id) }}>
                            <RefreshIcon />
                          </div>
                          <div style={{ cursor: 'pointer' }} onClick={() => { this.deleteCPAuth(row.id) }}>
                            <DeleteIcon />
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            :
            <p style={{ textAlign: 'center' }}>No hay ningun cp autorizado.</p>
        }
      </Grid>
    )
  }
}

export { CP as default }
